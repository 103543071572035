import React, { useRef } from "react";
import {
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

export default function Grade({ grade, comment }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef();
  const validGrades = [
    "A+",
    "A",
    "A-",
    "B+",
    "B",
    "B-",
    "C+",
    "C",
    "C-",
    "D+",
    "D",
    "D-",
    "F",
  ];

  return (
    <>
      {validGrades.includes(grade) && (
        <>
          <Button
            w={24}
            h={32}
            position={"absolute"}
            left={-16}
            backgroundColor={"white"}
            onClick={onOpen}
            ref={buttonRef}
          >
            <Image
              position={"absolute"}
              maxW={"unset"}
              left={-6}
              w={32}
              src={`/grade-${grade}-icon.png`}
              zIndex={"overlay"}
            />
          </Button>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p={8} maxW={"2xl"}>
              <ModalHeader>Comments:</ModalHeader>
              <ModalCloseButton />
              <ModalBody fontSize={"xl"}>
                <Text pb={4} whiteSpace={"pre-wrap"}>
                  {comment}
                </Text>
                <Text>
                  Please feel free to update your article and submit again for a
                  better grade.
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}
