const endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

export async function getTopic(token) {
  try {
    const response = await fetch(`${endpoint}/topic`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    return null;
  }
}

export async function postWriting(id, topic, writing, token) {
  try {
    const response = await fetch(`${endpoint}/writing`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id,
        topic,
        writing,
      }),
    });
    const data = await response.json();

    return {
      grade: data.grade,
      comment: data.comment,
    };
  } catch (error) {
    return {
      topic: "N",
      prompt: "Service Unavailable",
    };
  }
}

export async function getTips(topic, writing, token) {
  try {
    const response = await fetch(`${endpoint}/tip`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        topic,
        writing,
      }),
    });
    const data = await response.json();

    return data.tips;
  } catch (error) {
    return {
      topic: "N",
      prompt: "Service Unavailable",
    };
  }
}
