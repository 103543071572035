import { useState, useContext, useRef, useEffect, forwardRef } from "react";
import { useTimeoutCallback } from "@react-hook/timeout";
import {
  Box,
  Textarea,
  Tooltip,
  Image,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { getTips } from "../../lib/api";
import { AuthContext } from "../../provider/AuthContext";

const WritingArea = forwardRef(({ topic, text, disabled, onChange }, ref) => {
  const [lightBulbVisible, setLightBulbVisible] = useState(false);
  const [tip, setTip] = useState("");
  const [tipVisible, setTipVisible] = useState(false);
  const { getJwtToken } = useContext(AuthContext);
  const tipPopupRef = useRef(null);
  const lightBulbRef = useRef(null);

  const [delayedShowLightBulb, cancelShowLightBulb] = useTimeoutCallback(
    async () => {
      const token = await getJwtToken();
      const tip = await getTips(topic, text, token);
      setTip(tip);
      setLightBulbVisible(true);
    },
    6000
  );

  const handleKeyDown = () => {
    setLightBulbVisible(false);
    cancelShowLightBulb();
    delayedShowLightBulb();
  };

  const toggleTip = () => {
    if (tipVisible) {
      setTipVisible(false);
    } else {
      setTipVisible(true);
    }
  };

  const hideTip = (event) => {
    if (event.target !== lightBulbRef.current) {
      setTipVisible(false);
    }
  };

  useOutsideClick({
    ref: tipPopupRef,
    handler: hideTip,
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]); // Adjust the height whenever the text prop changes

  return (
    <Box position={"relative"}>
      <Textarea
        value={text}
        onChange={onChange}
        rows={16}
        height={"auto"}
        placeholder="Write here ..."
        disabled={disabled}
        fontSize={"xl"}
        lineHeight={"taller"}
        backgroundImage={
          "repeating-linear-gradient(to bottom, transparent, transparent 39px, #ccc 39px, #ccc 40px)"
        }
        px={4}
        border={"none"}
        _focusVisible={{ border: "none" }}
        onFocus={delayedShowLightBulb}
        onBlur={cancelShowLightBulb}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
      <Tooltip label={"Got stuck? Click to find some prompts"}>
        <Image
          src="/light-bulb-icon.svg"
          position={"absolute"}
          right={4}
          top={4}
          w={12}
          h={12}
          cursor={"pointer"}
          display={lightBulbVisible ? "block" : "none"}
          backgroundColor={"white"}
          boxShadow={"md"}
          onClick={toggleTip}
          ref={lightBulbRef}
        />
      </Tooltip>
      {tipVisible && (
        <Box
          position={"absolute"}
          right={20}
          top={4}
          px={8}
          py={4}
          verticalAlign={"middle"}
          backgroundColor={"white"}
          boxShadow={"md"}
          overflow={"hidden"}
          ref={tipPopupRef}
          transition={"all 0.2s ease-in-out"}
        >
          <Text whiteSpace={"pre-wrap"}>{tip}</Text>
        </Box>
      )}
    </Box>
  );
});

export default WritingArea;
