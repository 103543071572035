import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Header({ title }) {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      height="63px"
      px="6"
      bg="white"
      boxShadow="md"
    >
      <Flex>
        <Image src="/logo50.png" alt="Logo" height="40px" />
        <Text fontSize="2xl" fontWeight="bold" ml="2">
          GPT Tutor
        </Text>
      </Flex>
      <Text fontSize="lg" fontWeight="bold">
        {title}
      </Text>
      <Menu>
        <MenuButton as={Box} cursor="pointer">
          <Flex>
            <Image
              src="/user-profile.png"
              alt="User Profile"
              height="30px"
              borderRadius="full"
            />
            <ChevronDownIcon my="2" />
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem>Profile</MenuItem>
          <MenuItem>Settings</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
