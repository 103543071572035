import React, { createContext, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useLocalStorage("authState", null);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const signIn = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);

      console.log(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setAuthState({
          username,
          user,
          challengeName: user.challengeName,
        });
        navigate("/change-password");
      } else {
        console.log(user.signInUserSession);
        setAuthState({
          username,
          signInUserSession: user.signInUserSession,
        });
      }
    } catch (error) {
      console.error("Error signing in", error);
    }
  };

  const changePassword = async (password) => {
    if (authState?.challengeName === "NEW_PASSWORD_REQUIRED") {
      const { username, user } = authState;
      try {
        const loggedUser = await Auth.completeNewPassword(
          user,
          password,
          user.challengeParam.requiredAttributes
        );
        setAuthState({
          username,
          signInUserSession: loggedUser.signInUserSession,
        });
      } catch (error) {
        console.error("Error changing password", error);
      }
    }
  };

  const tokenExpired = () => {
    if (!authState?.signInUserSession) {
      return true;
    }
    const expiresAt = authState.signInUserSession.accessToken.payload.exp;
    if (Date.now() / 1000 > expiresAt) {
      return true;
    }
    return false;
  };

  const refreshSession = async () => {
    const { username } = authState;
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("Token refreshed");
      setAuthState({
        username,
        signInUserSession: user.signInUserSession,
      });
      return user.signInUserSession.accessToken.jwtToken; // Return the new token directly
    } catch (error) {
      console.error("Error refreshing session", error);
    }
  };

  const getJwtToken = async () => {
    if (tokenExpired()) {
      console.log("token expired");
      const refreshedToken = await refreshSession();
      console.log("after refreshing token");
      return refreshedToken;
    }
    return authState?.signInUserSession.accessToken.jwtToken;
  };

  useEffect(() => {
    console.log("AuthContext start refreshing");
    if (!authState && pathname !== "/login") {
      navigate("/login?to=" + pathname + search);
    } else if (authState?.challengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/change-password");
    }
  }, [authState, pathname, search, navigate]);

  return (
    <AuthContext.Provider
      value={{ authState, getJwtToken, signIn, changePassword }}
    >
      {children}
    </AuthContext.Provider>
  );
};
