import React, { useContext, useState } from "react";
import { AuthContext } from "../provider/AuthContext";
import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";

const PasswordChangePage = () => {
  const { authState, changePassword } = useContext(AuthContext);
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword(password);
  };

  if (authState?.challengeName !== "NEW_PASSWORD_REQUIRED") {
    return <p>You do not need to change your password at this time.</p>;
  }

  return (
    <Box as="form" p={5} onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel>New Password</FormLabel>
        <Input type="password" onChange={(e) => setPassword(e.target.value)} />
      </FormControl>
      <Button mt={4} type="submit">
        Change Password
      </Button>
    </Box>
  );
};

export default PasswordChangePage;
