import { useState, useContext, useEffect, useRef } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { getTopic, postWriting } from "../../lib/api";
import { AuthContext } from "../../provider/AuthContext";
import Header from "../../component/Header";
import Description from "./Description";
import WritingArea from "./WritingArea";
import Grade from "./Grade";
import Title from "./Title";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function WritingPage() {
  const [id, setId] = useLocalStorage("id", uuidv4());
  const [topic, setTopic] = useLocalStorage("topic", "");
  const [description, setDescription] = useLocalStorage("description", "");
  const [writing, setWriting] = useLocalStorage("writing", "");
  const [grade, setGrade] = useLocalStorage("grade", "");
  const [comment, setComment] = useLocalStorage("comment", "");
  const [writingDisabled, setWritingDisabled] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { getJwtToken } = useContext(AuthContext);
  const [urlSearchParam] = useSearchParams();
  const writingAreaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // const fillOutTopic = async () => {
    //   const token = await getJwtToken();
    //   const { topic, description } = await getTopic(token);
    //   setTopic(topic);
    //   setDescription(description);
    // };
    // if (!topic) fillOutTopic();
    setReadyToSubmit(writing.length > 0);
    if (urlSearchParam.has("new")) {
      setId(uuidv4());
      setTopic("");
      setDescription("");
      setWriting("");
      setGrade("");
      setComment("");
      navigate("/writing"); // remove the ending "?new"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWritingChange = (event) => {
    const writing = event.target.value;
    setWriting(writing);
    setReadyToSubmit(writing.length > 0);
  };

  const handleTitleEnter = () => {
    if (writingAreaRef.current) {
      writingAreaRef.current.focus();
      console.log("Title enter");
    }
  };

  const handleSubmit = async () => {
    setWritingDisabled(true);
    try {
      const token = await getJwtToken();
      const review = await postWriting(id, topic, writing, token);
      setGrade(review.grade);
      setComment(review.comment);
    } catch (error) {
      alert("Error submitting writing");
    } finally {
      setWritingDisabled(false);
    }
  };

  return (
    <>
      <Header title="Writing" />
      <Box
        maxW={"4xl"}
        margin={"auto"}
        px={16}
        h={"auto"}
        boxShadow="md"
        bg="white"
        fontFamily={"Ginto Normal, verdana, sans-serif"}
      >
        <Image src="/title-example.png" alt="Title" w={"full"} my={"auto"} />
        <Flex mt={8}>
          <Box position={"relative"}>
            {grade && <Grade grade={grade} comment={comment} />}
          </Box>
          <Box w={"full"} px={4}>
            <Title
              text={topic}
              onChange={(event) => setTopic(event.target.value)}
              onEnter={handleTitleEnter}
            />
            <Description text={description} />
          </Box>
        </Flex>
        <WritingArea
          topic={topic}
          text={writing}
          disabled={writingDisabled}
          onChange={handleWritingChange}
          ref={writingAreaRef}
        />
        <Flex justifyContent="flex-end" px={4} py={8}>
          <Button
            isDisabled={true}
            colorScheme="blue"
            width={48}
            borderRadius={"full"}
            mx={8}
          >
            Leave
          </Button>
          <Button
            isDisabled={!readyToSubmit}
            colorScheme="blue"
            width={48}
            borderRadius={"full"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Flex>
        <style jsx="true" global="true">{`
          body {
            background-color: #eeeeee;
          }
        `}</style>
      </Box>
    </>
  );
}
