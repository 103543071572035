import { Input } from "@chakra-ui/react";

export default function Title({ text, onChange, onEnter }) {
  const determineFontSize = () => {
    if (text.length > 52) return "1.2rem";
    if (text.length > 42) return "1.5rem";
    return "1.8rem"; // Default font size
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // prevent the new line from being added
      event.preventDefault();
      onEnter();
    }
  };

  return (
    <Input
      placeholder="Title Goes Here ..."
      value={text}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      my={5}
      px={0}
      fontSize={determineFontSize()}
      fontWeight={"bold"}
      border={"none"}
      borderRadius={0}
      _focusVisible={{ border: "none", borderBottom: "1px solid gray" }}
      _hover={{ borderColor: "black" }}
    />
  );
}
