import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../provider/AuthContext";
import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

const LoginPage = () => {
  const { signIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [linkAfterLogin, setLinkAfterLogin] = useState("");
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const to = params.get("to");
      if (to) {
        setLinkAfterLogin(to);
      }
    }
  }, [search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signIn(email, password);
    if (linkAfterLogin) {
      navigate(linkAfterLogin);
    }
  };

  return (
    <Box as="form" p={5} onSubmit={handleSubmit}>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input type="email" onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Password</FormLabel>
        <Input type="password" onChange={(e) => setPassword(e.target.value)} />
      </FormControl>
      <Button mt={4} type="submit">
        Log in
      </Button>
    </Box>
  );
};

export default LoginPage;
