import { useState, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Description({ text }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const textRef = useRef(null);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box py={4} pb={8} position={"relative"}>
      {text && (
        <>
          <Text
            position={"relative"}
            maxH={isExpanded ? "none" : 16} // Assume line-height of 1.8rem, thus two lines would be 3.6rem
            whiteSpace={"pre-wrap"}
            fontStyle={"italic"}
            fontSize={"md"}
            ref={textRef}
            overflow={"hidden"}
            onClick={handleClick}
            cursor="pointer"
            transition={"all 0.5s ease-in-out"}
            _after={{
              content: isExpanded ? "none" : "''",
              transform: "scaleY(0.5)",
              fontStyle: "normal",
              position: "absolute",
              textAlign: "center",
              bottom: 0,
              right: 0,
              width: "100%",
              height: "1.8rem",
              backgroundImage:
                "linear-gradient(180deg, transparent, white 50%)",
            }}
          >
            {text}
          </Text>
          <ChevronDownIcon
            position={"relative"}
            fontSize={"3xl"}
            w={"full"}
            cursor={"pointer"}
            onClick={handleClick}
            transform={isExpanded ? "rotate(180deg)" : "rotate(0deg)"}
          />
        </>
      )}
    </Box>
  );
}
